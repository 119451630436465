import React, { Component } from "react";
import Slider from "react-slick";
import CardProyecto from "../../organisms/Proyectos/CardProyecto"
import SliderCarousel from "../../organisms/SliderCarousel/SliderCarousel"
import { css } from "@emotion/react";
import Text from "../../atoms/Text/Text"
import CardAvanceObraProyecto from "../../organisms/Proyectos/CardAvanceObraProyecto"

const cardCss = css`
  pointer-events: none;
  padding-left: calc(100vw * (calc(20 / var(--width_base))));
  padding-right: calc(100vw * (calc(20 / var(--width_base))));
  @media (max-width: 767px) {
    padding-left: initial;
    padding-right: initial;
  }
`;

const content1Css = css`
  max-width: calc(100vw * (calc(1160 / var(--width_base))));
  margin-left: auto;
  margin-right: auto;
  padding-bottom: calc(100vw * (calc(30 / var(--width_base))));

  .slick-slide > div{
    cursor: pointer;  
  }
  
  .card-imagen::before{
    content: "";
    position: absolute;
    background-color: black;
    inset: 0;
    z-index: 1;
    opacity: .7;
  }

  .slick-current .card-imagen::before{
    display: none;
  }
  
  //.slick-current .card-direction, 
  //.slick-current  .card-num span,
  //.slick-current .card-dorm{
  //  color: #4A4A49;
  //}
  //
  //.slick-current .card-text{
  //  background-color: white;
  //}
  
`;

const content2Css = css`
  padding-bottom: calc(100vw * (calc(69 / var(--width_base))));
  padding-top: calc(100vw * (calc(60 / var(--width_base))));
  
  @media (max-width: 767px) {
    padding-top: calc(100vw * (calc(30 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(15 / var(--width_base))));
  }
  
  & .slick-arrow{
     z-index: 10;
  }

  & .slick-arrow,& .slick-arrow svg{
    width: calc(100vw * (calc(40 / var(--width_base))));
    height: calc(100vw * (calc(68 / var(--width_base))));
  }

  & .slick-next{
    @media (min-width: 768px) {
      right: calc(100vw * (calc(-43 / var(--width_base)))) !important;       
    }
  }

  & .slick-prev{
    @media (min-width: 768px) {
      left: calc(100vw * (calc(-43 / var(--width_base)))) !important;       
    }
  }
  
`;

const titleCss = css`
  font-size: calc(100vw * (calc(23 / var(--width_base))));
  line-height: calc(100vw * (calc(23 / var(--width_base))));
  margin-bottom: calc(100vw * (calc(55 / var(--width_base))));
  font-family: "Montserrat Bold", sans-serif;
`;

const sectionCss = css`
  background-color: #5E5E5E;
  padding-top: calc(100vw * (calc(50 / var(--width_base))));
  @media (max-width: 767px) {
    background-color: #444443;
  }
`;

const section2Css = css`
  background-color: #4A4A49;
  @media (max-width: 767px) {
    background-color: #444443;          
  }
`;

const textCss = css`
  font-size: calc(100vw * (calc(23 / var(--width_base))));
  line-height: calc(100vw * (calc(30 / var(--width_base))));
  @media (max-width: 767px) {
    font-size: calc(100vw * (calc(14 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
  }
  //&:not(:first-of-type){
  &{
    margin-bottom: calc(100vw * (calc(60 / var(--width_base))));
    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(30 / var(--width_base))));          
    }
  }
`;


const textEntrega = css`
  font-size: calc(100vw * (calc(23 / var(--width_base))));
  line-height: calc(100vw * (calc(23 / var(--width_base))));
  @media (max-width: 767px) {
    font-size: calc(100vw * (calc(14 / var(--width_base))));
    line-height: calc(100vw * (calc(20 / var(--width_base))));
  }
`;

const contentSlider = css`
  & > .slick-slider > .slick-list > .slick-track > .slick-slide{
    overflow: hidden;
    padding-left: calc(100vw * (calc(108 / var(--width_base))));
    padding-right: calc(100vw * (calc(108 / var(--width_base))));
    @media (max-width: 767px) {
      padding-left: initial;
      padding-right: initial;
    }
  }

  & .slider-carusel__item{
    padding-left: calc(100vw * (calc(16 / var(--width_base))));
    padding-right: calc(100vw * (calc(16 / var(--width_base))));
    @media (max-width: 767px) {
      padding-left: initial;
      padding-right: initial;
    }
  }

  & .slider-carusel__imagen{
    height: calc(100vw * (calc(290 / var(--width_base)))); 
  }
  
`;

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "slick-prev slick-arrow" +
      (currentSlide === 0 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === 0}
    type="button"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.36 67.47">
      <polyline fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="7px" points="35.86 3.5 4.95 34.41 34.51 63.97" />
    </svg>
  </button>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "slick-next slick-arrow" +
      (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1}
    type="button"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.36 67.47">
      <polyline fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="7px" points="3.5 3.5 34.41 34.41 4.84 63.97" />
    </svg>
  </button>
);

const settings2 = {
  dots: false,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplaySpeed: 4000,
  pauseOnHover: false,
  pauseOnFocus: false,
  infinite: false,
  prevArrow: <SlickArrowLeft/>,
  nextArrow: <SlickArrowRight/>,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        arrows: true,
      }
    }
  ]
};

export default class ProyectosAvance extends Component {

  constructor(props) {

    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      data: null,
      title: null,
      titlePage: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      data: this.props.data,
      title: this.props.title,
      titlePage: this.props.titlePage
    });
  }

  render() {
    return (
      <>
        <section css={sectionCss}>
          {this.state.titlePage && <Text classEle="d-none" data={`Slider ${this.state.titlePage}`} typeEle="h2"/>}
          <Text data={this.state.title} align="center" styleCss={titleCss} colorText="white" data-aos="fade-right" data-delay="200"/>
          <div css={content1Css}>
            <Slider
              asNavFor={this.state.nav1}
              ref={slider => (this.slider2 = slider)}
              slidesToShow={3}
              swipeToSlide={true}
              focusOnSelect={true}
              infinite={false}
              prevArrow={<SlickArrowLeft/>}
              nextArrow={<SlickArrowRight/>}
              responsive={[
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    arrows: true,
                  }
                }
              ]}
            >
              {
                this.state.data && this.state.data.map((dato,i)=>(
                  <div key={i} css={cardCss}>
                    {
                      (dato?.template?.templateName === "Proximo Proyecto") ?
                        <>
                          <CardProyecto data={dato} isProyecto={false} classEle="only-desk"/>
                          <CardAvanceObraProyecto data={dato} isProyecto={false} classEle="only-mov"/>
                        </>
                        :
                        <>
                          <CardProyecto data={dato} isProyecto={true} classEle="only-desk"/>
                          <CardAvanceObraProyecto data={dato} isProyecto={true} classEle="only-mov"/>
                        </>
                    }
                  </div>
                ))
              }
            </Slider>
          </div>
        </section>
        <section css={section2Css}>
          <div css={content2Css}>
            <div css={contentSlider}>
              <Slider
                asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)}
                arrows={false}
                dots={false}
                swipe={false}
                fade={false}
                infinite={false}
                responsive={[
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                    }
                  }
                ]}
              >
                {
                  this.state.data && this.state.data.map((dato,i)=>(
                    <div key={i}>
                      {
                        (dato?.template?.templateName === "Proximo Proyecto") ?
                          <>
                            {dato?.template?.proximoProyecto?.entregaEstimadaAvance && <Text data={`ENTREGA ESTIMADA: ${dato?.template?.proximoProyecto?.entregaEstimadaAvance.toUpperCase()}`} colorText="#BFBDBD" align="center" styleCss={textEntrega} data-aos="fade-right" data-aos-delay="200"/>}
                            {dato?.template?.proximoProyecto?.fechaAvance && <Text data={dato?.template?.proximoProyecto?.fechaAvance.toUpperCase()} colorText="#BFBDBD" align="center" styleCss={textCss} data-aos="fade-right" data-aos-delay="200"/>}
                            {/*{dato?.template?.proximoProyecto?.avanceDeObraAvance && <Text data={`AVANCE DE OBRA: ${dato?.template?.proximoProyecto?.avanceDeObraAvance.toUpperCase()}`} colorText="#BFBDBD" align="center" styleCss={textCss} data-aos="fade-right" data-aos-delay="200"/>}*/}
                            {dato?.template?.proximoProyecto?.galeriaAvance && <SliderCarousel data={dato?.template?.proximoProyecto?.galeriaAvance} settings={settings2} index={i}/>}
                          </>
                          :
                          <>
                            {dato?.template?.proyectoEnVenta?.entregaEstimadaAvance && <Text data={`ENTREGA ESTIMADA: ${dato?.template?.proyectoEnVenta?.entregaEstimadaAvance.toUpperCase()}`} colorText="#BFBDBD" align="center" styleCss={textEntrega} data-aos="fade-right" data-aos-delay="200"/>}
                            {dato?.template?.proyectoEnVenta?.fechaAvance && <Text data={dato?.template?.proyectoEnVenta?.fechaAvance.toUpperCase()} colorText="#BFBDBD" align="center" styleCss={textCss} data-aos="fade-right" data-aos-delay="200"/>}
                            {/*{dato?.template?.proyectoEnVenta?.avanceDeObraAvance && <Text data={`AVANCE DE OBRA: ${dato?.template?.proyectoEnVenta?.avanceDeObraAvance.toUpperCase()}`} colorText="#BFBDBD" align="center" styleCss={textCss} data-aos="fade-right" data-aos-delay="200"/>}*/}
                            {
                              dato?.template?.proyectoEnVenta?.galeriaAvance ?
                                <SliderCarousel data={dato?.template?.proyectoEnVenta?.galeriaAvance} settings={settings2} index={i}/>
                                :
                                <Text data="No hay Imagenes" styleCss={textCss} colorText="#BFBDBD" align="center" data-aos="fade-right" data-aos-delay="200"/>
                            }
                          </>
                      }
                    </div>
                  ))
                }

              </Slider>
            </div>
          </div>
        </section>
      </>
    );
  }
}